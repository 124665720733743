import {Component, OnInit,ViewEncapsulation,Output,EventEmitter,ViewChild} from '@angular/core';
import {ColumnMode, DatatableComponent} from '@swimlane/ngx-datatable';
import {FormBuilder, FormGroup} from '@angular/forms';
import { CoreSidebarService } from '../../../../../@core/components/core-sidebar/core-sidebar.service';






@Component({
  selector: 'app-new-simulation-sidebar',
  templateUrl: './new-simulation-sidebar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NewSimulationSidebarComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
   

  
  /**
     * Constructor
     *
     * @param {CoreSidebarService} _coreSidebarService
     */

  
  
  constructor(private _coreSidebarService: CoreSidebarService) {

  }
  
  /**
   * filterUpdate
   *
   * @param event
   */
  
  
  ngOnInit(): void {
  }

 
  
  
   /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebarCra(name): void {
      this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    }
  
  
}
