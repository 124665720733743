<div class="slideout-content">
  <!-- Modal to add new user starts-->
  <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
    <div class="modal-dialog" style="width: 35%;" >
      <form class="add-new-user modal-content pt-0" >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebarCra('new-simulation-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title" id="exampleModalLabel">Simulation Portage Salarial</h5>
        </div>
        <form  class="modal-body flex-grow-1">
     
        </form>
      </form>
    </div>
  </div>
  <!-- Modal to add new user Ends-->
</div>
