import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apple-now',
  templateUrl: './apple-now.component.html',
  styleUrls: ['./apple-now.component.scss']
})
export class AppleNowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
