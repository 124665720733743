<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="users-list-wrapper">
            <div class="modal-body flex-grow-1" style="margin:0px 10px;">
                <div *ngIf="showResults" class="content-wrapper container-xxl p-0">
                    <h3 class="modal-title" id="exampleModalLabel" style="margin-left: 10px;"><b>Résultats Simulation Portage Salarial</b></h3>
                    <hr style="margin: auto;width: 100%;margin-bottom: 20px;">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <div class="sidebar-about">
                                <div class="form-group">
                                    <div class="centered-content">
                                        <span class="large-text green-text">{{simul.netPayerAvantImpot.toFixed(2)}} € <small>/mois</small></span>
                                        <h4 class="green-text">Net à payer avant impôt</h4>
                                    </div>
                                    <small><b style="color: black;">Soit <span style="color: rgb(0, 187, 0);">{{(100*simul.netPayerAvantImpot / simul.caMensuelHt).toFixed(2)}}%</span> de votre CA en rémunération nette</b></small>
                                    <hr style="margin: auto;width: 100%;margin-bottom: 30px;">
                                    <h3><b style="color: rgb(255, 174, 0);" class="mb-1">Détail:</b></h3>
                                    <div class="row">
                                        <h6 style="font-size: 18px;" class="col-md-8"><b style="color: #5f5875;">Chiffre d'affaires mensuel HT: </b></h6>
                                        <h6 style="font-size: 18px;" class="col-md-4" style="text-align: end;margin-top: 5px;" class="col-md-4"><b style="color: #5f5875;"> {{simul.caMensuelHt.toFixed(2)}} €</b></h6>
                                    </div>
                                    <hr style="margin: auto;width: 95%;">
                                    <div class="row">
                                        <h6 style="color: #5f5875;font-size: 16px;margin-top: 5px;" class="col-md-8">Frais de gestion (5%) : </h6>
                                        <h6 style="color: #5f5875;font-size: 16px;text-align: end;margin-top: 5px;" class="col-md-4">{{simul.fraisGestion.toFixed(2)}} €</h6>
                                    </div>
                                    <div class="row">
                                        <h6 style="font-size: 18px;" class="col-md-8"><b style="color: #5f5875;">Montant disponible HT: </b></h6>
                                        <h6 style="font-size: 18px;" class="col-md-4" style="text-align: end;margin-top: 5px;" class="col-md-4"><b style="color: #5f5875;"> {{simul.montantDisponibleHt.toFixed(2)}} €</b></h6>
                                    </div>
                                    <hr style="margin: auto;width: 95%;">
                                    <div class="row">
                                        <h6 style="color: #5f5875;font-size: 16px;margin-top: 5px;" class="col-md-8">Frais non refacturés diductibles : </h6>
                                        <h6 style="color: #5f5875;font-size: 16px;text-align: end;margin-top: 5px;" class="col-md-4"> {{simul.fraisProDeductible.toFixed(2)}} €</h6>
                                    </div>
                                    <div class="row">
                                        <h6 style="font-size: 18px;" class="col-md-8"><b style="color: #5f5875;">CA disponible pour le salaire: </b></h6>
                                        <h6 style="font-size: 18px;" class="col-md-4" style="text-align: end;"><b style="color: #5f5875;text-align: end;"> {{simul.caDisponibleSalaire.toFixed(2)}} €</b></h6>
                                    </div>
                                    <hr style="margin: auto;width: 95%;">
                                    <div class="row">
                                        <h6 style="color: #5f5875;font-size: 16px;margin-top: 5px;" class="col-md-8">Total des cotisation : </h6>
                                        <h6 style="color: #5f5875;font-size: 16px;text-align: end;margin-top: 5px;" class="col-md-4"> {{simul.totalCotisations.toFixed(2)}} €</h6>
                                    </div>
                                    <div class="row">
                                        <h6 style="color: #5f5875;font-size: 16px;margin-top: 5px;" class="col-md-8">Restitution de frais : </h6>
                                        <h6 style="color: #5f5875;font-size: 16px;text-align: end;margin-top: 5px;" class="col-md-4">{{simul.restitutionFrais.toFixed(2)}} €</h6>
                                    </div>
                                    <div class="row">
                                        <h6 style="font-size: 18px;" class="col-md-8"><b style="color: rgb(0, 187, 0);margin-top: 5px;">Net à payer avant impot : </b></h6>
                                        <h6 style="font-size: 18px;" class="col-md-4" style="text-align: end;"><b style="color: rgb(0, 187, 0);text-align: end;margin-top: 5px;"> {{simul.netPayerAvantImpot.toFixed(2)}} €</b></h6>
                                    </div>
                                    <hr style="margin: auto;width: 95%;">
                                    <div class="row">
                                        <h6 style="color: #5f5875;font-size: 16px;margin-top: 5px;" class="col-md-8">Impot sur le revenu : </h6>
                                        <h6 style="color: #5f5875;font-size: 16px;text-align: end;margin-top: 5px;" class="col-md-4">{{simul.impotSurLeRevenu.toFixed(2)}} €</h6>
                                    </div>
                                    <hr style="margin: auto;width: 95%;">
                                    <div class="row">
                                        <h6 style="font-size: 18px;" class="col-md-8"><b style="color: rgb(21, 150, 255);">Net à payer : </b></h6>
                                        <h6 style="font-size: 18px;" class="col-md-4" style="text-align: end;"><b style="color: rgb(21, 150, 255);"> {{simul.netPayer.toFixed(2)}} €</b></h6>
                                    </div>
                                    <div class="row">
                                        <h6 style="font-size: 18px;" class="col-md-8"><b style="color: rgb(21, 150, 255);"></b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div style="display: flex; justify-content: flex-end;">
                                <a href="https://calendly.com/portixy-service-client/30min" target="_blank" class="btn btn-primary">Prendre rendez-vous</a>
                            </div>

                            <div class="chart-container">
                                <ngx-charts-pie-chart
                                    class="custom-legend"
                                    [scheme]="colorScheme"
                                    [results]="graphData"
                                    [doughnut]="true"
                                    [tooltipDisabled]="false"
                                    [legend]="true"
                                    [legendPosition]="'right'"
                                    [view]="view">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                    <h3 style="color: rgb(185, 185, 185);font-size: 13px;margin: auto;" class="mt-1">**Résultats basés sur les informations fournies, et ne peuvent faire l'objet d'un engagement contractuel. Pour une estimation plus précise, prenez rendez-vous avec nos experts.</h3>
                    <hr style="margin: auto;width: 100%;margin-bottom: 20px;">
                </div>
            </div>

        </section>
        <section class="users-list-wrapper">
            <div class="modal-header mb-1">
                <h3 class="modal-title" id="exampleModalLabel" style="margin-left: 10px;"><b>Hypothèses Simulation Portage Salarial</b></h3>
            </div>
            <form [formGroup]="formulairSimuls" class="modal-body flex-grow-1" style="margin:0px 10px ;">

                <h6 style="margin-top: 5px;">Votre identité</h6>
                <hr style="margin: auto;width: 100%;margin-bottom: 20px;" >

                <!-- Votre identité section -->
                <div class="row mt-1 mb-1 pl-1 pr-1 ml-1">
                    <div class="form-group col-md-6">
                        <label class="form-label" for="nom">Nom *</label>
                        <input type="text" id="nom" formControlName="nom" class="form-control" placeholder="Votre Nom" required>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label" for="prenom">Prénom *</label>
                        <input type="text" id="prenom" formControlName="prenom" class="form-control"  placeholder="Votre Prenom" required>
                    </div>
                </div>

                <!-- Votre email et téléphone -->
                <div class="row mt-1 mb-1 pl-1 pr-1 ml-1">
                    <div class="form-group col-md-6">
                        <label class="form-label" for="email">E-mail *</label>
                        <input type="email" id="email" formControlName="email" class="form-control"  placeholder="Votre email" required>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label" for="tele">Télèphone *</label>
                        <input type="text" id="tele" formControlName="telephone" class="form-control"  placeholder="Votre N° de telephone" required>
                    </div>
                </div>

                <!-- Votre mission section -->
                <h6 style="margin-top: 5px;">Votre mission</h6>
                <hr style="margin: auto;width: 100%;margin-bottom: 20px;">

                <!-- TJM and Durée de mission -->
                <div class="row mt-1 mb-1 pl-1 pr-1 ml-1">
                    <div class="form-group col-md-6">
                        <label class="form-label" for="tjm">Votre TJM *</label>
                        <input type="text" id="tjm" formControlName="tjm" class="form-control"  placeholder=" €/jour" required>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label" for="Durée">Durée de mission *</label>
                        <input type="number" id="duree" formControlName="duree" class="form-control" placeholder="3" required>
                    </div>
                </div>

                <!-- Frais professionnels non facturés au client and Nombre jours moyen -->
                <div class="row mt-1 mb-1 pl-1 pr-1 ml-1">
                    <div class="form-group col-md-6">
                        <label class="form-label" for="Frais">Frais professionnels non facturés au client</label>
                        <input type="text" id="frais" formControlName="fraisProfessionnels" class="form-control"  placeholder="200" required>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label" for="nbrJrs">Nombre jours moyen *</label>
                        <input type="number" id="nbrJrs" formControlName="nombreJours" class="form-control"  placeholder="20" required>
                    </div>
                </div>

                <!-- Votre taux d'imposition réell -->
                <div class="mt-1 mb-1 pl-1 pr-1 ml-1">
                    <label class="form-label" for="taux">Votre taux d'imposition *</label>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <select
                                formControlName="typeTauxImposition" class="form-control" id="typeConsultant" >
                                <option value="0" selected disabled>Selectionner type TauxImposition : </option>
                                <option value="Non_imposable">Non_imposable</option>
                                <option value="Personalisé">Personalisé</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <input type="text" id="tauxperso" formControlName="valeurTauxImposition"  class="form-control"  placeholder="Saisi taux perso" >
                        </div>
                    </div>
                </div>

                <!-- Simulation button -->
                <div style="margin: 15px 0px;" class="option-item">
                    <div class="menu-icon d-in-line">
                        <button *ngIf="!showResults" style="padding:7px 12px ;color:white;background-color:rgb(238, 209, 48);border-radius: 5px;cursor: pointer;margin:10px 0px ;" id="simuler" (click)="simuler()" [disabled]="formulairSimuls.invalid">simuler</button>
                        <div *ngIf="formulairSimuls.invalid" style="color:red;">Veuillez remplir tous les champs obligatoires.</div>
                    </div>
                </div>


            </form>

        </section>
    </div>
</div>

