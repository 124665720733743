export class Formulaire_Simulation {
    nom: string;
    prenom: string;
    email: string;
    telephone: string;
    tjm: number;
    nombreJours: number;
    duree: number;
    fraisProfessionnels: number;
    typeTauxImposition: string;
    valeurTauxImposition: number;
 }
 
 export class Simulation {
    roi :number;
    caMensuelHt: number;
    fraisGestion: number;
    montantDisponibleHt: number;
    fraisProDeductible: number;
    caDisponibleSalaire: number;
    totalCotisations: number;
    restitutionFrais: number;
    netPayerAvantImpot: number;
    impotSurLeRevenu: number;
    netPayer: number;
   }
   
   