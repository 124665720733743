import { Component, OnInit, ViewChild, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { CoreSidebarService } from '../../../../@core/components/core-sidebar/core-sidebar.service';
import { Simulation } from './model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; // Import Validators and FormGroup

import { CoreConfigService } from '../../../../@core/services/config.service';

@Component({
    selector: 'app-simulation',
    templateUrl: './simulation.component.html',
    styleUrls: ['./simulation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SimulationComponent implements OnInit {
    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild('simuler', { static: false }) simulerButton: ElementRef;

    showResults = false;
    public simuls: Simulation[] = [];
    public simul: Simulation = new Simulation();
    public formulairSimuls: FormGroup;  // Change this from Formulaire_Simulation to FormGroup

    constructor(
        private _coreSidebarService: CoreSidebarService,
        private formBuilder: FormBuilder  // Inject FormBuilder
    ) {
        // Initialize the form here
        this.formulairSimuls = this.formBuilder.group({
            nom: ['', Validators.required],
            prenom: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            telephone: ['', Validators.required],
            tjm: [400, Validators.required],
            duree: [3, Validators.required],
            fraisProfessionnels: [100, Validators.required],
            nombreJours: [20, Validators.required],
            typeTauxImposition: ['Non_imposable', Validators.required],
            valeurTauxImposition: [0, Validators.required]
        });
    }
    toggleSidebarCra(name): void {
        this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    }

    total: number;

    formatLabel(data): string {
        const label = data.name;
        const percentage = (data.value / this.total) * 100;
        return `${label} ${percentage.toFixed(1)}%`;
    }

    ngOnInit(): void {
        this.calculer();
    }



    simuler() {
        console.log('good');
        this.showResults = false;
        this.calculer();
        this.showResults = true;
        this.scrollToTop();
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }


    view: any[] = undefined;
    colorScheme = { domain: [] };

    graphData: any[] = [];

    private calculer() {
        console.log('good');
        const formValues = this.formulairSimuls.value;
        this.simul.caMensuelHt = formValues.tjm * formValues.nombreJours;
        this.simul.fraisGestion = this.simul.caMensuelHt * -0.05;
        this.simul.montantDisponibleHt = (this.simul.caMensuelHt + this.simul.fraisGestion) * 1;
        this.simul.fraisProDeductible = formValues.fraisProfessionnels * -1;
        this.simul.caDisponibleSalaire = (this.simul.montantDisponibleHt + this.simul.fraisProDeductible) * 1;
        this.simul.totalCotisations = this.simul.caDisponibleSalaire * -0.45;
        this.simul.restitutionFrais = formValues.fraisProfessionnels * 1;
        this.simul.netPayerAvantImpot = (this.simul.caDisponibleSalaire + this.simul.totalCotisations + this.simul.restitutionFrais) * 1;
        if (formValues.typeTauxImposition == "Non_imposable") {
            this.simul.impotSurLeRevenu = 0 ;
        } else if (formValues.typeTauxImposition == "Personalisé") {
            this.simul.impotSurLeRevenu = this.simul.netPayerAvantImpot * formValues.valeurTauxImposition / 100;
        };
        this.simul.netPayer = (this.simul.netPayerAvantImpot - this.simul.impotSurLeRevenu) * 1;
        if (this.simul.caMensuelHt) {
            this.simul.roi = this.simul.netPayerAvantImpot / this.simul.caMensuelHt;
        } else {
            this.simul.roi = 0;
        }
        // Graphe
        this.graphData = [
            { name: 'Net avant impôt', color: '#5AA454', value: this.simul.netPayerAvantImpot },
            { name: 'Cotisations', color: '#A10A28', value: this.simul.totalCotisations * -1 },
            { name: 'Prélèvement à la source', color: '#C7B42C', value: this.simul.impotSurLeRevenu * -1 },
            { name: 'Frais gestion ', color: '#AAAAAA', value: this.simul.fraisGestion * -1 },
        ];

        // Calculate the total sum of the values
        this.total = this.graphData.reduce((sum, item) => sum + item.value, 0);

        this.colorScheme.domain = this.graphData.map(d => d.color);
    }

}
