import { Component, OnInit } from '@angular/core';
import { EmailService } from 'src/app/email.service'; // Assurez-vous que ce chemin est correct

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    email: string; // Pour la liaison de données avec ngModel

    constructor(private emailService: EmailService) { } // Injection du service dans le constructeur

    ngOnInit(): void {
    }
    onSubscribe() { // Fonction appelée lorsque le formulaire est soumis
        this.emailService.sendSubscriptionEmail(this.email).subscribe(
            () => console.log('Email sent'),
            err => console.error('Error sending email', err)
        );
    }
}
