<!-- Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-location-plus'></i>
                                </div>
                                <a href="#" target="_blank">1-7 Cours Valmy PARIS LA DEFENSE</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:gestion@portixy.fr">gestion@portixy.fr</a>
                            </li>

                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-phone'></i>
                                </div>
                                <a href="tel:+33183171380" > 01.88.88.88.36</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">

                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <a href="https://www.linkedin.com/company/portixy/" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a><img src="assets/img/logoportixy.png" alt="Images"></a>
                    </div>
                </div>
            </div>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">

                    <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Acceuil</a></li>
                    <!-- <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Actualités </a></li> -->
                    <li class="nav-item"><a routerLink="/simulation" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Simulation</a></li>

                </ul>
                <ul class="navbar-nav for-responsive">

                    <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Acceuil</a></li>
                    <!-- <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Actualités Portage</a></li> -->
                    <li class="nav-item"><a routerLink="/simulation" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Simulation</a></li>

                </ul>
                <div class="others-options d-flex align-items-center">
                    <div class="option-item">

                    </div>
                    <div class="option-item">
                        <div class="menu-icon d-in-line">
                            <span class="burger-menu menu-icon-two"><i class='flaticon-menu'></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Sidebar Modal -->
<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-header">
            <div class="sidebar-logo">
                <img src="assets/img/logoportixy.png" alt="Image">
            </div>
            <span class="close-btn sidebar-modal-close-btn"><i class="bx bx-x"></i></span>
        </div>

        <div class="sidebar-about">
            <div class="title">
                <p>Votre partenaire de qualité pour un portage à proximité.</p>
            </div>
        </div>

        <div class="contact-us">
            <h2>Contact Us</h2>

            <ul>
                <li>
                    <i class='bx bx-current-location'></i>
                    1-7 Cours Valmy 92800 PARIS LA DEFENSE CEDEX
                </li>
                <li>
                    <i class='bx bx-mail-send'></i>
                    <a href="mailto:gestion@portixy.fr">gestion@portixy.fr</a>
                </li>
                <li>
                    <i class='bx bx-phone-call'></i>
                    <a href="tel:+33183171380"> +033-831-713-80</a>
                </li>
            </ul>
        </div>


        <div class="sidebar-follow-us">
            <h2>Follow Us</h2>

            <ul class="social-wrap">
                <li>
                    <div class="head-icon">
                        <a href="https://www.linkedin.com/company/portixy/" target="_blank"><i class='bx bxl-linkedin'></i></a>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->
