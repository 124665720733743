<!-- Banner Area -->
<!-- Zone de bannière -->
<div class="banner-area-three banner-bg">
    <div class="container">
        <div class="banner-content banner-content-three">
            <h1>Votre partenaire de qualité pour un portage à proximité</h1>
            <p>Alliant réactivité et transparence pour un portage salarial d'excellence..</p>
            <div class="banner-btn">
                <a routerLink="/simulation" class="contact-btn">Votre simulation</a>
                <a href="https://calendly.com/portixy-service-client/30min" target="_blank" class="get-btn">Contactez-nous</a>
            </div>
        </div>
    </div>
</div>
<!-- Fin de la zone de bannière -->
<!-- Banner Area End -->


<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <span>Nos valeurs</span>
                    <h2>Un écosystème sur mesure qui vous aide à atteindre vos objectifs</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="service-text">
                    <p>Chez Portixy, nous sommes là pour vous accompagner dans votre parcours en vous permettant de vous concentrer sur votre activité principale en toute tranquillité. Nous offrons des services de qualité supérieure, une réactivité hors pair et une transparence totale pour vous aider à réussir dans votre domaine.</p>
                </div>
            </div>
        </div>

        <div class="row pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3><a routerLink="/services-details">Réactivité</a></h3>
                    <p>
                        Nous sommes à votre disposition pour répondre rapidement à toutes vos demandes et vous accompagner dans les meilleurs délais pour vous permettre de vous concentrer sur votre activité professionnelle.
                    </p>

                    <div class="services-card-bottom"></div>
                </div>
            </div>



            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a routerLink="/services-details">Transparence</a></h3>
                    <p>
                        Nous mettons tout en œuvre pour vous offrir un service transparent. Nous vous informons de manière claire et précise sur tous les aspects de votre contrat de portage salarial et nous sommes à votre écoute pour répondre à toutes vos questions.
                    </p>

                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a routerLink="/services-details">Qualité de service</a></h3>
                    <p>Nous sommes fiers de vous offrir un service de qualité supérieure qui répondra à toutes vos attentes. Notre équipe est experte en portage salarial et nous mettons tout en œuvre pour faciliter votre vie professionnelle au quotidien.</p>

                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>


    </div>




    <!-- About Area -->
    <div class="about-area pt-100 pb-70" id="about">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-md-12 pl-0">
                    <div class="about-img-3">
                        <img src="assets/img/RelationTripartie.jpeg" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content about-content-max">
                        <span>Relation tripartite</span>
                        <h2>Le portage salarial crée une relation entre  <b>trois parties : </b> </h2>
                        <h3> le salarié porté <b>(vous)</b> , l'entreprise de portage salarial <b>(votre employeur)</b>  et l'entreprise cliente <b>(votre client)</b> . </h3>
                        <p>En tant que salarié en portage salarial, vous êtes employé par la société de portage salarial et travaillez pour des clients externes, mais vous êtes totalement libre sur le plan commercial et fixez vous-même les tarifs et les modalités d'exécution de vos missions. La société de portage salarial <b>s'occupe</b> également de toutes les <b>démarches administratives</b> liées à votre activité professionnelle, , telles que la déclaration à l'Urssaf, au RSI, aux caisses de retraite, la facturation des clients et la gestion du salaire.</p>

                        <div class="row">
                            <div class="col-lg-4 col-md-4 pr-0">
                                <div class="about-counter">
                                    <i class="flaticon-medal"></i>
                                    <div class="content">
                                        <h3>1</h3>
                                        <span>Liberté et autonomier</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 pr-0">
                                <div class="about-counter">
                                    <i class="flaticon-team"></i>
                                    <div class="content">
                                        <h3>2</h3>
                                        <span>Simplification administrative </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 pr-0">
                                <div class="about-counter">
                                    <i class="flaticon-briefcase"></i>
                                    <div class="content">
                                        <h3>3</h3>
                                        <span>Accompagnement et formation</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About Area End -->

    <!-- Guidelines Area -->
    <div class="guidelines-area-two">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12 pl-0">
                    <div class="guidelines-img">
                        <img src="assets/img/cadrelegal.jpg" alt="Images">
                    </div>
                </div>

                <div class="col-lg-7 col-md-12 pr-0">
                    <div class="choose-content choose-content-bg">
                        <div class="choose-max">

                            <div class="section-title">
                                <span><h2>Cadre Legal</h2></span>
                                <h2>Article L.1254-1 du code du travail</h2>
                                <p>
                                    "Le portage salarial est un contrat de travail conclu entre une entreprise de portage salarial et un salarié porté, qui a pour objet de mettre à disposition du salarié porté auprès d'une ou plusieurs entreprises utilisatrices, les compétences et les savoir-faire de celui-ci, sous la responsabilité de l'entreprise de portage salarial.</p>
                                <p>
                                    Le salarié porté exerce sous sa propre responsabilité une activité de prestation de services auprès de l'entreprise utilisatrice, en toute indépendance et sous son propre nom. Il fixe lui-même les modalités d'exécution de sa mission et est rémunéré par l'entreprise de portage salarial qui facture l'entreprise utilisatrice en son nom et pour son compte.</p>
                                <p>
                                    Le salarié porté bénéficie des garanties et protections attachées au contrat de travail qui le lie à l'entreprise de portage salarial."</p>
                                <div class="signature"><img src="assets/img/signature2.png" alt="Images"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Guidelines Area End -->

    <!-- Choose Area -->
    <div class="choose-area-two pt-100 pb-70">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12 pl-0">
                    <div class="choose-content choose-content-bg">
                        <div class="choose-max">
                            <div class="section-title">
                                <span>Se lancer avec Portixy</span>
                                <h2>Chez Portixy, nous mettons notre expertise et notre transparence au service de nos salariés portés.</h2>
                                <p>Nous sommes une équipe réactive et à l'écoute, qui met tout en œuvre pour accompagner nos salariés de manière personnalisée et leur offrir un suivi régulier. La confiance est au cœur de notre relation avec nos salariés portés, qui peuvent compter sur nous pour les soutenir et les protéger dans leur activité professionnelle </p>
                                <a href="https://calendly.com/portixy-service-client/30min" target="_blank" class="default-btn">Rendez-vous</a>
                            </div>

                            <div class="row border-top">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="choose-content-list choose-content-list-color">
                                        <i class="flaticon-sort"></i>
                                        <div class="content">
                                            <h3>Transparance</h3>
                                            <p>Disposer d'informations fiables et transparentes à tout moment.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="choose-content-list choose-content-list-color">
                                        <i class="flaticon-briefcase"></i>
                                        <div class="content">
                                            <h3>Service</h3>
                                            <p>La qualité de service pour faciliter votre vie professionnelle au quotidien.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="choose-content-list choose-content-list-color">
                                        <i class="flaticon-analytics"></i>
                                        <div class="content">
                                            <h3>Réactivité</h3>
                                            <p>Capacité à traiter rapidement les demandes de nos clients et de nos salariés portés.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="choose-content-list choose-content-list-color">
                                        <i class="flaticon-chess-pieces"></i>
                                        <div class="content">
                                            <h3>Confiance</h3>
                                            <p>Se reflète dans notre gestion transparente et professionnelle de leurs affaires.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-12 pr-0">
                    <div class="choose-img-3">
                        <img src="assets/img/choose-img5.jpg" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Choose Area End -->

    <!-- Client Area -->
    <div class="client-area-two ptb-100">
        <div class="container">
            <div class="section-title text-center">
                <span>Ce que disent nos clients</span>
                <h2>Des professionnels satisfaits de notre accompagnement</h2>
                <p>Chez Portixy, nous sommes fiers de travailler avec des professionnels de tous horizons, qui ont choisi le portage salarial pour mener à bien leur activité. Nos clients apprécient notre approche personnalisée et notre engagement envers la qualité, la transparence et la réactivité. .</p>
            </div>

            <div class="client-slider owl-carousel owl-theme pt-45">
                <div class="client-card">
                    <h3>Salim B</h3>
                    <span> </span>
                    <ul>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </li>
                    </ul>
                    <p>J'ai trouvé en Portixy un partenaire de confiance, toujours disponible pour répondre à mes questions et m'aider dans mes démarches. .</p>
                    <i class="flaticon-quote client-card-quote"></i>
                </div>

                <div class="client-card">
                    <h3>Marie L</h3>
                    <span> </span>
                    <ul>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </li>
                    </ul>
                    <p>Grâce à Portixy, j'ai pu me concentrer sur mon métier en toute sérénité, en bénéficiant d'un accompagnement sur mesure.</p>
                    <i class="flaticon-quote client-card-quote"></i>
                </div>

                <div class="client-card">
                    <h3>Jemie D</h3>
                    <span> </span>
                    <ul>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </li>
                    </ul>
                    <p>Portixy a su me proposer des solutions adaptées à mes besoins, en toute transparence et avec un suivi de qualité.</p>
                    <i class="flaticon-quote client-card-quote"></i>
                </div>
            </div>
        </div>
    </div>
    <!-- Client Area End -->

    <!-- Leader Area -->
    <div class="leader-area">
        <div class="container">
            <div class="leader-content">
                <div class="section-title text-center">
                    <h2>Rejoignez-nous et libérez votre potentiel de réussite !</h2>
                    <p>Embrassez l'aventure du freelance tout en bénéficiant de la stabilité d'un salarié.</p>
                    <p> </p>
                    <p> </p>
                    <div class="banner-btn">
                        <a href="https://calendly.com/portixy-service-client/30min" target="_blank" class="default-btn">Contactez-nous</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Leader Area End -->


