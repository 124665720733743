<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a><img src="assets/img/logoportixy.png" alt="Images"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-list-two">
                        <li>
                            <i class='bx bx-time'></i>
                            Lun - Ven: 8.00 - 18.00
                        </li>
                        <li>
                            <i class='bx bx-location-plus'></i>
                            1-7 cours valmy 92800 paris la defense cedex
                        </li>
                        <li>
                            <i class='bx bx-phone'></i>
                            <a href="tel:+33183171380">+33183171380</a>
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:gestion@portixy.fr">gestion@portixy.fr</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">
                    <h3>News</h3>

                    <div class="newsletter-area">
                        <form class="newsletter-form" (ngSubmit)="onSubscribe()">
                            <input type="email" class="form-control" placeholder="Votre Email*" name="EMAIL" [(ngModel)]="email">
                            <button class="default-btn" type="submit">S'abonner</button>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>© Portixy <a href="https://portixy.fr/" target="_blank"> Portage à proximité </a></p>
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->
