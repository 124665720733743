import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmailService {

    constructor(private http: HttpClient) { }

    sendSubscriptionEmail(email: string) {
        const emailData = {
            to: email, // l'adresse email de l'utilisateur qui s'abonne
            from: 'your-email@example.com', // votre adresse email SendGrid
            subject: 'Subscription Confirmation',
            text: 'Thank you for subscribing to our newsletter!'
        };

        return this.http.post('https://api.sendgrid.com/v3/mail/send', emailData, {
            headers: {
                'Authorization': `Bearer ${'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'}`, // replace YOUR_SENDGRID_API_KEY with your actual SendGrid API Key
                'Content-Type': 'application/json'
            }
        });
    }
}
